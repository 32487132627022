import React, {FC, useState} from 'react'
import Tab from 'react-bootstrap/Tab'
import Tabs from 'react-bootstrap/Tabs'

import {useIntl} from 'react-intl'

import {TablesWidget5} from '../../../../../_dashboard/partials/widgets'
import {PageTitle} from '../../../../../_dashboard/layout/core'

const AppliedProvidersDetailsTabs: FC = () => {
  const [key, setKey] = useState('order')
  const intl = useIntl()
  return (
    <div>
      <PageTitle
      >
        {intl.formatMessage({id: 'ADMIN.APPLIED.PROVIDERS.DETAILS'})}
      </PageTitle>

      <div className='col-xxl-12'>
        <TablesWidget5 className='card-xxl-stretch mb-5 mb-xxl-8' />
      </div>{' '}

    </div>
  )
}

export default AppliedProvidersDetailsTabs
