/* eslint-disable jsx-a11y/anchor-is-valid */
import {Link} from 'react-router-dom'
import {toAbsoluteUrl} from '../../../../helpers'

type Props = {
  className: string
}

const EngageWidget10 = ({className}: Props) => (
  <div className={`card card-flush ${className}`}>
    <div
      className='card-body d-flex flex-column justify-content-between mt-9 bgi-no-repeat bgi-size-cover bgi-position-x-center pb-0'
      style={{
        backgroundPosition: '100% 50%',
        backgroundImage: `url('${toAbsoluteUrl('/media/stock/900x600/42.png')}')`,
      }}
    >
      <div className='mb-3'>
        <div className='fs-2hx fw-bold text-gray-800 text-center mb-13'>
          <span className='me-2'>
            Bienvenue <br />
            <span className='position-relative d-inline-block text-danger'>
              <Link
                to='/crafted/pages/profile/overview'
                className='text-primary
              opacity-75-hover'
              >
                GSO ADMIN
              </Link>

              <span className='position-absolute opacity-15 bottom-0 start-0 border-4 border-danger border-bottom w-100'></span>
            </span>
          </span>
        </div>

        {/* <div className='text-center'>
          <a href='#'>Upgrade Now</a>
        </div> */}
      </div>
      <div className='d-flex'>
        {' '}
        <img
          className='theme-light-show w-250px mx-5'
          style={{borderRadius: '20px'}}
          src={toAbsoluteUrl('/media/misc/photovoltaic.jpg')}
          alt=''
        />
        <img
          className='theme-light-show w-250px'
          style={{borderRadius: '20px'}}
          src={toAbsoluteUrl('/media/misc/photovoltaic.jpeg')}
          alt=''
        />
      </div>
    </div>
  </div>
)
export {EngageWidget10}
