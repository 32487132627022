import React, { useEffect, useState } from 'react';
import { UpdatePassword3, getAll } from '../../../../../app/services/common_requests';

const cellStyle: React.CSSProperties = {
  textAlign:'center',
  border: '2px solid black',
  borderRadius: '10px'
};

const tableStyle: React.CSSProperties = {
  border: '2px solid black',
  borderRadius: '8px',
};

const InvoicesTable = () => {
  const [invoices, setInvoices] = useState<any[]>([]);
  const [refreshKey, setRefreshKey] = useState<number>(0);


  useEffect(() => {
    const fetchInvoices = async () => {
      try {
        const query = {};
        const URL = '/tracking';
        const data = await getAll(query, URL);

        if (data && data.data && data.data.length > 0) {
          setInvoices(data.data);
        } else {
          console.log('No data returned from API');
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchInvoices();
  }, [refreshKey]);
  const handleCheckboxChange = async (invoiceId: string, fieldName: string, checked: boolean) => {
    try {
      const updatedFields = { [fieldName]: checked };
      const response = await UpdatePassword3(invoiceId, updatedFields, '/tracking/status');
      setRefreshKey((prevKey) => prevKey + 1);

    } catch (error) {
      console.error('Error updating invoice:', error);

    }
  };


  return (
    <div className='table-responsive' style={{  margin: '20px' }}>
      <h2 style={{ textAlign: 'center', fontWeight: 'bold', paddingBottom: 10 }}>Suivi de l’avancement des projets Clients</h2>
      <table className='table align-middle ' style={tableStyle}>
        <thead>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            <th style={cellStyle}>Référence du projet</th>
            <th style={cellStyle}>Client</th>
            <th colSpan={2} style={cellStyle}>Devis</th>
            <th style={cellStyle}>Etude</th>
            <th style={cellStyle}>STEG</th>
            <th style={cellStyle}>ANME</th>
            <th style={cellStyle}>Ministère de l'énergie</th>
            <th colSpan={4} style={cellStyle}>Installation</th>
          </tr>
          <tr className='text-start text-muted fw-bolder fs-7 text-uppercase gs-0'>
            <th style={cellStyle}></th>
            <th style={cellStyle}></th>
            <th style={cellStyle}> Nombre </th>
            <th style={cellStyle}> Display </th>
            <th style={cellStyle}></th>
            <th style={cellStyle}></th>
            <th style={cellStyle}></th>
            <th style={cellStyle}></th>
            <th style={cellStyle}>Livraison</th>
            <th style={cellStyle}>Structure</th>
            <th style={cellStyle}>Panneaux</th>
            <th style={cellStyle}>Branchement</th>
          </tr>
        </thead>
        <tbody className='text-gray-600 fw-bold'>
          {invoices.map((invoice) => (
            <tr key={invoice._id}>
              <td style={cellStyle}>{invoice.reference}</td>
              <td style={cellStyle}>{invoice.client?.firstName} {invoice.client?.lastName}</td>
              <td style={cellStyle}>{invoice.nbreDevis}</td>
              <td style={cellStyle}><input type="checkbox" defaultChecked={invoice.display} /></td>
              <td style={cellStyle}>
                <input
                  type="checkbox"
                  checked={invoice.etude}
                  onChange={(e) => handleCheckboxChange(invoice._id, 'etude', e.target.checked)}
                />
              </td>
              <td style={cellStyle}>
                <input
                  type="checkbox"
                  checked={invoice.steg}
                  onChange={(e) => handleCheckboxChange(invoice._id, 'steg', e.target.checked)}
                />
              </td>
              <td style={cellStyle}>
                <input
                  type="checkbox"
                  checked={invoice.anme}
                  onChange={(e) => handleCheckboxChange(invoice._id, 'anme', e.target.checked)}
                />
              </td>
              <td style={cellStyle}>
                <input
                  type="checkbox"
                  checked={invoice.ministre}
                  onChange={(e) => handleCheckboxChange(invoice._id, 'ministre', e.target.checked)}
                />
              </td>
              <td style={cellStyle}>
                <input
                  type="checkbox"
                  checked={invoice.livraison}
                  onChange={(e) => handleCheckboxChange(invoice._id, 'livraison', e.target.checked)}
                />
              </td>
              <td style={cellStyle}>
                <input
                  type="checkbox"
                  checked={invoice.structure}
                  onChange={(e) => handleCheckboxChange(invoice._id, 'structure', e.target.checked)}
                />
              </td>
              <td style={cellStyle}>
                <input
                  type="checkbox"
                  checked={invoice.panneaux}
                  onChange={(e) => handleCheckboxChange(invoice._id, 'panneaux', e.target.checked)}
                />
              </td>
              <td style={cellStyle}>
                <input
                  type="checkbox"
                  checked={invoice.branchement}
                  onChange={(e) => handleCheckboxChange(invoice._id, 'branchement', e.target.checked)}
                />
              </td>
            </tr>
          ))}
          {invoices.length === 0 && (
            <tr>
              <td colSpan={12} style={cellStyle}>
                <div className='d-flex text-center w-100 align-content-center justify-content-center'>
                  No matching records found
                </div>
              </td>
            </tr>
          )}
        </tbody>
      </table>
    </div>
  );
};

export default InvoicesTable;
