import React, {useEffect, useState} from 'react'
import {KTSVG, toAbsoluteUrl} from '../../../helpers'
import dataAxios from '../../../../app/modules/auth/core/dataAxios'
import {useParams} from 'react-router'
import {toast} from 'react-toastify'

type Props = {
  className: string
}

const TablesWidget5: React.FC<Props> = ({className}) => {
  const [providerData, setProviderData] = useState<any>({})
  const [buttonsDisabled, setButtonsDisabled] = useState<boolean>(() => {
    const storedState = localStorage.getItem('buttonsDisabled')
    return storedState ? JSON.parse(storedState) : false
  })

  const {id} = useParams<{id: string}>()

  useEffect(() => {
    const fetchProviderData = async () => {
      try {
        const response = await dataAxios.get(`/devisProvider/applications/${id}`)
        const provider = response.data.data[0]
        setProviderData(provider)
      } catch (error) {
        console.error('Error fetching provider data:', error)
      }
    }

    fetchProviderData()
  }, [id])

  const handleStatusChange = async (providerId: string) => {
    const newStatus = '2000'

    try {
      await dataAxios.put(`/devisProvider/${id}/acceptProvider/${providerId}`, {
        status: newStatus,
      })

      toast.success(`Demande de  a été accepté avec succès`, {
        position: 'top-right',
        autoClose: 4000,
      })

      const updatedDevis = {...providerData}
      const updatedProviders = updatedDevis.appliedProviders.map((provider: any) => {
        if (provider.provider._id === providerId) {
          return {...provider, status: newStatus}
        }
        return provider
      })

      setProviderData({...updatedDevis, appliedProviders: updatedProviders})
      setButtonsDisabled(true)
      localStorage.setItem('buttonsDisabled', JSON.stringify(true))
    } catch (error) {
      console.error('Error updating devis status:', error)
    }
  }
  return (
    <div className={`card ${className}`}>
      {/* begin::Header */}
      <div className='card-header border-0 pt-5'>
        <h3 className='card-title align-items-start flex-column'>
          <span className='card-label fw-bold fs-3 mb-1'>Liste des fournisseurs</span>
        </h3>
      </div>
      {/* end::Header */}
      {/* begin::Body */}
      <div className='card-body py-3'>
        <div className='table-responsive'>
          {providerData?.appliedProviders?.length ? (
            <table className='table table-row-dashed table-row-gray-200 align-middle gs-0 gy-4'>
              <thead>
                <tr className='border-0'>
                  <th className='p-0 w-50px'></th>
                  <th className='p-0 min-w-150px'></th>
                  <th className='p-0 min-w-140px'></th>
                  <th className='p-0 min-w-110px'></th>
                  <th className='p-0 min-w-50px'></th>
                </tr>
              </thead>
              <tbody>
                {providerData.appliedProviders.map((provider: any, index: number) => (
                  <tr key={index}>
                    <td>
                      <div className='symbol symbol-45px me-2'>
                        <span className='symbol-label'>
                          <img
                            src={toAbsoluteUrl(`/media/svg/brand-logos/${provider.logo}.svg`)}
                            className='h-50 align-self-center'
                            alt=''
                          />
                        </span>
                      </div>
                    </td>
                    <td>
                      {provider.provider.firstName} {provider.provider.lastName}
                      <span className='text-muted fw-semibold d-block'>
                        {provider.provider.email}
                      </span>
                    </td>
                    <td className='text-end'>
                      <a
                        href={
                          process.env.REACT_APP_API_URL_IMG + provider?.devisProviderFile?.slice(1)
                        }
                        download={
                          process.env.REACT_APP_API_URL_IMG + provider?.devisProviderFile?.slice(1)
                        }
                      >
                        <i
                          className='fas fa-download'
                          style={{fontSize: '24px', marginRight: '5px'}}
                        />
                      </a>
                    </td>
                    <td className='text-end'>
                      {provider.status === '2000' ? (
                        <button className='btn btn-success' disabled>
                          Accepté
                        </button>
                      ) : provider.status === '3000' ? (
                        <button
                          className='btn btn-warning'
                          onClick={() => handleStatusChange(provider.provider._id)}
                        >
                          Accepter
                        </button>
                      ) : (
                        <button
                          className={`btn ${buttonsDisabled ? 'btn-danger' : 'btn-warning'}`}
                          onClick={() => handleStatusChange(provider.provider._id)}
                          disabled={buttonsDisabled}
                        >
                          {buttonsDisabled ? 'Réfusé' : 'Accepter'}
                        </button>
                      )}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div className='fs-20'>Aucune application trouvée</div>
          )}
        </div>
      </div>
    </div>
  )
}

export {TablesWidget5}
