import {lazy, FC, Suspense} from 'react'
import {Route, Routes, Navigate} from 'react-router-dom'
import {MasterLayout} from '../../_dashboard/layout/MasterLayout'
import TopBarProgress from 'react-topbar-progress-indicator'
import {DashboardWrapper} from '../pages/dashboard/DashboardWrapper'
import {MenuTestPage} from '../pages/MenuTestPage'
import {getCSSVariableValue} from '../../_dashboard/assets/ts/_utils'
import {WithChildren} from '../../_dashboard/helpers'
import BuilderPageWrapper from '../pages/layout-builder/BuilderPageWrapper'
import AppliedProvidersDetailsTabs from '../pages/providerDevis-management/providerDevis-list/AppliedProviders/AppliedProviders'

const PrivateRoutes = () => {
  const ProfilePage = lazy(() => import('../modules/profile/ProfilePage'))
  const UsersPage = lazy(() => import('../pages/user-management/UsersPage'))
  const InterventionPage = lazy(() => import('../pages/interventions-management/InterventionsPage'))
  const ClientPage = lazy(() => import('../pages/clients-management/ClientsPage'))
  const ProviderPage = lazy(() => import('../pages/provider-management/ProviderPage'))
  const ClientDevisPage = lazy(() => import('../pages/clientDevis-management/clientDevisPage'))
  const ProviderDevisPage = lazy(
    () => import('../pages/providerDevis-management/providerDevisPage')
  )
  const ProductivityPage = lazy(() => import('../pages/productivity-management/productivityPage'))
  const ComplaintsPage = lazy(() => import('../pages/complaints/complaintsPage'))
  const ComplaintscategoriesPage = lazy(
    () => import('../pages/complaintscategories/complaintscategoriesPage')
  )

  const PublicityPage = lazy(() => import('../pages/publicity/publicityPage'))
  const InscriptionPage = lazy(() => import('../pages/inscription-management/inscriptionPage'))
  const FacturesPage = lazy(() => import('../pages/factures-management/facturesPage'))
  const MaintenancePage = lazy(
    () => import('../pages/maintenancePlanning-management/maintenancePlanning-Page')
  )

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        {/* Redirect to Dashboard after success login/registartion */}
        <Route path='auth/*' element={<Navigate to='/dashboard' />} />
        <Route path='provider-devis/list/:id' element={<AppliedProvidersDetailsTabs />} />

        {/* Pages */}
        <Route path='dashboard' element={<DashboardWrapper />} />
        <Route path='builder' element={<BuilderPageWrapper />} />
        <Route path='menu-test' element={<MenuTestPage />} />
        {/* Lazy Modules */}
        <Route
          path='crafted/pages/profile/*'
          element={
            <SuspensedView>
              <ProfilePage />
            </SuspensedView>
          }
        />

        <Route
          path='user-management/*'
          element={
            <SuspensedView>
              <UsersPage />
            </SuspensedView>
          }
        />
        <Route
          path='client-management/*'
          element={
            <SuspensedView>
              <ClientPage />
            </SuspensedView>
          }
        />

        <Route
          path='client-devis/*'
          element={
            <SuspensedView>
              <ClientDevisPage />
            </SuspensedView>
          }
        />
        <Route
          path='provider-management/*'
          element={
            <SuspensedView>
              <ProviderPage />
            </SuspensedView>
          }
        />
        <Route
          path='provider-devis/*'
          element={
            <SuspensedView>
              <ProviderDevisPage />
            </SuspensedView>
          }
        />
        <Route
          path='productivity-management/*'
          element={
            <SuspensedView>
              <ProductivityPage />
            </SuspensedView>
          }
        />
        <Route
          path='intervention-management/*'
          element={
            <SuspensedView>
              <InterventionPage />
            </SuspensedView>
          }
        />

        <Route
          path='complaints/*'
          element={
            <SuspensedView>
              <ComplaintsPage />
            </SuspensedView>
          }
        />
        <Route
          path='publicity/*'
          element={
            <SuspensedView>
              <PublicityPage />
            </SuspensedView>
          }
        />
        <Route
          path='complaintscategories/*'
          element={
            <SuspensedView>
              <ComplaintscategoriesPage />
            </SuspensedView>
          }
        />
        <Route
          path='inscription-management/*'
          element={
            <SuspensedView>
              <InscriptionPage />
            </SuspensedView>
          }
        />
        <Route
          path='factures-management/*'
          element={
            <SuspensedView>
              <FacturesPage />
            </SuspensedView>
          }
        />
        <Route
          path='maintenance-management/*'
          element={
            <SuspensedView>
              <MaintenancePage />
            </SuspensedView>
          }
        />
        {/* Page Not Found */}
        <Route path='*' element={<Navigate to='/error/404' />} />
      </Route>
    </Routes>
  )
}

const SuspensedView: FC<WithChildren> = ({children}) => {
  const baseColor = getCSSVariableValue('--bs-primary')
  TopBarProgress.config({
    barColors: {
      '0': baseColor,
    },
    barThickness: 1,
    shadowBlur: 5,
  })
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>
}

export {PrivateRoutes}
