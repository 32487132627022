const QUERIES = {
  USERS_LIST: 'users',
  ROLES: 'roles',
  CLIENTS_LIST: 'clients',
  CLIENTDEVIS_LIST: 'clients Devis',

  INTERVENTIONS_LIST: 'interventions',
  INVOICES_LIST: 'invoices',
  DEVISCLIENTS_LIST: 'devis',
  PROVIDERS_LIST: 'provider',
  COMPLAINTS_LIST: 'reclamation',
  PUBLICITIES_LIST: 'publicities',


  FACTURES_LIST: 'factures',
  MAINTENANCE_PLANNING_LIST: 'maintenancePlanning',
  SUBSCRIPTIONS_LIST: 'clients/registration',
  PRODUCTIVITY_LIST: 'rendrement',

  RECLAMATION_CATEGORIES_LIST: 'reclamation_categories',
  STATISTICS: "stats"


}

export { QUERIES }
