import React, {useEffect, useRef} from 'react'
import ApexCharts, {ApexOptions} from 'apexcharts'
import {getCSSVariableValue} from '../../../../assets/ts/_utils'
import {useThemeMode} from '../../../layout/theme-mode/ThemeModeProvider'

type Props = {
  className: string
  chartSize?: number
  chartLine?: number
  chartRotate?: number
  data?: any
  name: string
}

const getChartId = (name: string) => 'kt_card_widget_17_chart_' + name

const CardsWidget17: React.FC<Props> = ({
  className,
  chartSize = 240,
  chartLine = 25,
  chartRotate = 145,
  data,
  name,
}) => {
  const chartRef = useRef<HTMLDivElement | null>(null)
  const {mode} = useThemeMode()
  const id = getChartId(name)

  const refreshChart = () => {
    if (!chartRef.current) {
      return
    }
    const chartOptions = createChartOptions(data, chartSize)
    const chart = new ApexCharts(chartRef.current, chartOptions)
    chart.render()
    return chart
  }

  useEffect(() => {
    const chart = refreshChart()
    return () => {
      if (chart) {
        chart.destroy()
      }
    }
  }, [mode, data, chartSize, chartLine, chartRotate])

  const getStatusText = (name: string) => {
    switch (name) {
      case 'DEVIS':
        return 'État des devis'
      case 'REC':
        return 'État des réclamations'
      case 'ADS':
        return 'État des publicités'
      case 'DEVIS_PROVIDER':
        return 'État des devis fournisseurs'
      default:
        return ''
    }
  }

  return (
    <div className={`card card-flush ${className}`}>
      <div className='card-header pt-5'>
        <div className='card-title d-flex flex-column'>
          <div className='d-flex align-items-center'>
            <span className='fs-18 fw-bold text-dark me-2 lh-1 ls-n2'>{getStatusText(name)}</span>
          </div>
          <span className='text-gray-400 pt-1 fw-semibold fs-6'>États</span>
        </div>
      </div>

      <div className='card-body pt-2 pb-4 d-flex flex-wrap align-items-center'>
        <div className='d-flex flex-center me-5 pt-2'>
          <div
            id={id}
            ref={chartRef}
            style={{minWidth: chartSize + 'px', minHeight: chartSize + 'px'}}
            data-kt-size={chartSize}
            data-kt-line={chartLine}
          ></div>
        </div>

        <div className='d-flex flex-column content-justify-center flex-row-fluid'>
          {data?.status && (
            <>
              <div className='d-flex fw-semibold align-items-center'>
                <div className='bullet w-8px h-3px rounded-2 bg-success me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>Acceptés</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>
                  {data.status['2000']?.count}
                </div>
              </div>
              <div className='d-flex fw-semibold align-items-center my-3'>
                <div className='bullet w-8px h-3px rounded-2 bg-primary me-3'></div>
                <div className='text-gray-500 flex-grow-1 me-4'>En attente</div>
                <div className='fw-bolder text-gray-700 text-xxl-end'>
                  {data.status['3000']?.count}
                </div>
              </div>
              <div className='d-flex fw-semibold align-items-center'>
                <div
                  className='bullet w-8px h-3px rounded-2 me-3'
                  style={{backgroundColor: '#FF0000'}}
                ></div>
                <div className='text-gray-500 flex-grow-1 me-4' style={{color: '#FF0000'}}>
                  Rejetés
                </div>
                <div className='fw-bolder text-xxl-end text-gray-700'>
                  {data.status['5000']?.count}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  )
}

const createChartOptions = (
  data: {status?: {[key: string]: {count: number}}; total?: number},
  chartSize: number
): ApexOptions => {
  const accepted = data?.status?.['2000']?.count || 0
  const pending = data?.status?.['3000']?.count || 0
  const rejected = data?.status?.['5000']?.count || 0
  const total = data?.total || 0

  const acceptedPercent = (accepted / total) * 100 || 0
  const pendingPercent = (pending / total) * 100 || 0
  const rejectedPercent = (rejected / total) * 100 || 0

  return {
    series: [acceptedPercent, pendingPercent, rejectedPercent],
    chart: {
      height: chartSize,
      type: 'radialBar',
    },
    plotOptions: {
      radialBar: {
        dataLabels: {
          name: {
            show: true,
          },
          value: {
            show: true,
            formatter: (val: number) => `${val.toFixed(2)}%`,
          },
        },
        hollow: {
          size: '50%',
        },
        track: {
          background: getCSSVariableValue('--bs-gray-200'),
        },
      },
    },
    labels: ['Acceptés', 'En attente', 'Rejetés'],
    colors: [
      getCSSVariableValue('--bs-success'),
      getCSSVariableValue('--bs-primary'),
      '#FF0000', // Red color for rejected
    ],
  }
}

export {CardsWidget17}
