import {AxiosError, AxiosResponse} from 'axios'
import {AuthModel} from './_models'

const AUTH_LOCAL_STORAGE_KEY = 'kt-auth-react-v'
const getAuth = (): AuthModel | undefined => {
  if (!localStorage) {
    return
  }

  const lsValue = localStorage.getItem(AUTH_LOCAL_STORAGE_KEY)
  if (!lsValue) {
    return
  }

  try {
    const auth: AuthModel = JSON.parse(lsValue) as AuthModel
    if (auth) {
      // You can easily check auth_token expiration also
      return auth
    }
  } catch (error) {
    console.error('AUTH LOCAL STORAGE PARSE ERROR', error)
  }
}

const setAuth = (auth: AuthModel) => {
  if (!localStorage) {
    return
  }

  try {
    const lsValue = JSON.stringify(auth)
    localStorage.setItem(AUTH_LOCAL_STORAGE_KEY, lsValue)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE SAVE ERROR', error)
  }
}

const removeAuth = () => {
  if (!localStorage) {
    return
  }

  try {
    localStorage.removeItem(AUTH_LOCAL_STORAGE_KEY)
  } catch (error) {
    console.error('AUTH LOCAL STORAGE REMOVE ERROR', error)
  }
}

// export function setupAxios(axios: any) {
//   axios.defaults.headers.Accept = 'application/json'
//   axios.interceptors.request.use(
//     (config: {headers: {Authorization: string}}) => {
//       const auth = getAuth()
//       if (auth && auth.data.access_token) {
//         config.headers.Authorization = `Bearer ${auth.data.access_token}`
//       }

//       return config
//     },
//     (err: any) => Promise.reject(err)
//   )
// }
// export function setupAxiosResponse(axios: any) {


//   axios.interceptors.response.use(
//     (response: AxiosResponse) => {
//       return response
//     },
//     (error: AxiosError) => {

//       if (error.response && error.response.status === 401) {
//         // Unauthorized error, log user out

//         localStorage.removeItem('token')
//         localStorage.removeItem('expirationTime')
//         window.location.reload() // Reload the app to clear state and redirect to login page
//       }

//       return Promise.reject(error)
//     }
//   )
// }

export {getAuth, setAuth, removeAuth, AUTH_LOCAL_STORAGE_KEY}
