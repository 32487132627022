import axios, {AxiosResponse} from 'axios'
import { ID, Response } from '../../_dashboard/helpers'
import { CommonQueryResponse } from './common_types'
import dataAxios from '../modules/auth/core/dataAxios'

const API_URL = process.env.REACT_APP_API_URL|| ''


const getAll = (query: any,URL:string): Promise<any> => {
  return dataAxios
  .get(`${API_URL+URL}`,{params:{...query}})
  .then((d: any) => { return d.data})
}

const getAllClients = (URL:string, params: any): Promise<any> => {
  return dataAxios
  .get(`${API_URL}${URL}`, { params })
 .then((d: any) => { return d.data})
}

 const UpdatePassword = (user: any,URL: String): Promise<any | undefined> => {
  return dataAxios
    .put(`${API_URL + URL}`,user )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const Updatestatusinscription = (user: any,URL: String): Promise<any | undefined> => {
  return dataAxios
    .patch(`${API_URL + URL}`,user )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const UpdatePassword2 = (id: any , user: any,URL: String): Promise<any | undefined> => {
  return dataAxios
    .patch(`${API_URL+URL}/${id}`,user )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const UpdatePassword3 = (id: any , user: any,URL: String): Promise<any | undefined> => {
  return dataAxios
    .put(`${API_URL+URL}/${id}`,user )
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const getOneById = (id: ID,URL:String): Promise<any | undefined> => {
  return dataAxios
    .get(`${API_URL+URL}/${id}`)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.data)
}

const createOne = (user: any,URL:String): Promise<any | undefined> => {
  return dataAxios
    .post(API_URL+URL, user)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}

const updateOne = (user: any,URL:String): Promise<any | undefined> => {
  return dataAxios
    .patch(`${API_URL+URL}/${user._id}`, user)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const updateClientDevis = (user: any,id:any,URL:String): Promise<any | undefined> => {
  return dataAxios
    .patch(`${API_URL+URL}/${id}/response`, user)
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const updateStatus = (status: string,id:String,URL:string): Promise<any | undefined> => {
  return dataAxios
    .put(`${API_URL+URL}/${id}`, {"status":status})
    .then((response: AxiosResponse<Response<any>>) => response.data)
    .then((response: Response<any>) => response.code)
}
const deleteOne = (userId: ID,URL:String): Promise<any | undefined> => {
  return dataAxios.delete(`${API_URL+URL}/${userId}`).then(() => {})
}

const deleteSelectedOne = (userIds: Array<ID>,URL:String): Promise<void> => {
  const requests = userIds.map((id) => dataAxios.delete(`${API_URL+URL}/${id}`))
  return axios.all(requests).then(() => {})
}
const acceptOne = (id: ID): Promise<any> => {
  return dataAxios.post(`${API_URL}/accept/${id}`);
};

const refuseOne = (id: ID): Promise<any> => {
  return dataAxios.post(`${API_URL}/refuse/${id}`);
};

export {UpdatePassword3,Updatestatusinscription , updateClientDevis,UpdatePassword2, acceptOne,UpdatePassword, refuseOne,updateStatus, getAllClients ,getAll, deleteOne, deleteSelectedOne, getOneById, createOne, updateOne}
