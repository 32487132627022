import axios, {AxiosError, AxiosResponse} from 'axios'
import * as authHelper from './AuthHelpers'

const API_URL = process.env.REACT_APP_API_URL

const formDataAxios = axios.create({
  baseURL: API_URL,
  headers: {
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'PUT, POST, DELETE, OPTIONS',
    'Access-Control-Allow-Credentials': true,
    'Access-Control-Allow-Headers': '*',
  },
})
formDataAxios.interceptors.response.use(
  (response: AxiosResponse) => {
    return response
  },
  (error: AxiosError) => {
    if (error.response && error.response.status === 401) {
      // Unauthorized error, log user out

      localStorage.removeItem('token')
      localStorage.removeItem('expirationTime')
     // authHelper.removeAuth()
      //window.location.reload() // Reload the app to clear state and redirect to login page
    }

    return Promise.reject(error)
  }
)

export default formDataAxios
