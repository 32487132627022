/* eslint-disable react/jsx-no-target-blank */
import React from 'react'
import {useIntl} from 'react-intl'
import {SidebarMenuItem} from './SidebarMenuItem'
import {useAuth} from '../../../../../app/modules/auth'

const SidebarMenuMain = () => {
  const intl = useIntl()
  const {auth, currentUser} = useAuth()
  const role = currentUser?.roles || auth?.data?.user?.role

  return (
    <>
      {(role.name === 'ROLE_SUPER_ADMIN' || role.name === 'ROLE_ADMIN') && (
        <>
          <SidebarMenuItem
            to='/dashboard'
            icon='/media/icons/duotune/graphs/gra008.svg'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/client-management'
            icon='/media/icons/duotune/communication/com006.svg'
            title={intl.formatMessage({id: 'MENU.CLIENTS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/client-devis'
            icon='/media/icons/duotune/abstract/abs037.svg'
            title={intl.formatMessage({id: 'MENU.CLIENTS.DEVIS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/provider-management'
            icon='/media/icons/duotune/abstract/abs025.svg'
            title={intl.formatMessage({id: 'MENU.PROVIDER'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/provider-devis'
            icon='/media/icons/duotune/abstract/abs034.svg'
            title={intl.formatMessage({id: 'MENU.PROVIDER.DEVIS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/user-management/users'
            icon='/media/icons/duotune/communication/com014.svg'
            title={intl.formatMessage({id: 'MENU.USERS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/complaints'
            icon='/media/icons/duotune/art/art010.svg'
            title={intl.formatMessage({id: 'MENU.COMPLAINS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/publicity'
            icon='/media/icons/duotune/social/soc007.svg'
            title={intl.formatMessage({id: 'MENU.PUBLICITY'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/complaintscategories'
            icon='/media/icons/duotune/abstract/abs015.svg'
            title={intl.formatMessage({id: 'MENU.CATEGORY_ADEVERTISEMENT'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/inscription-management'
            icon='/media/icons/duotune/communication/com005.svg'
            title={intl.formatMessage({id: 'MENU.INSCRIPTIONS'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/factures-management'
            icon='/media/icons/duotune/ecommerce/ecm008.svg'
            title={intl.formatMessage({id: 'MENU.FACTURES'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/productivity-management'
            icon='/media/icons/duotune/abstract/abs020.svg'
            title={intl.formatMessage({id: 'MENU.PRODUCTIVITY'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/maintenance-management'
            icon='/media/icons/duotune/general/gen026.svg'
            title={intl.formatMessage({id: 'MENU.MAINTENANCE'})}
            fontIcon='bi-layers'
          />
        </>
      )}

      {role.name === 'ROLE_TECHNIQUE' && (
        <>
          {' '}
          <SidebarMenuItem
            to='/dashboard'
            icon='/media/icons/duotune/graphs/gra008.svg'
            title={intl.formatMessage({id: 'MENU.DASHBOARD'})}
            fontIcon='bi-layers'
          />
          <SidebarMenuItem
            to='/maintenance-management'
            icon='/media/icons/duotune/ecommerce/ecm008.svg'
            title={intl.formatMessage({id: 'MENU.MAINTENANCE'})}
            fontIcon='bi-layers'
          />
        </>
      )}

      {role.name === 'ROLE_FINANCE' && (
        <SidebarMenuItem
          to='/factures-management'
          icon='/media/icons/duotune/general/gen051.svg'
          title={intl.formatMessage({id: 'MENU.FACTURES'})}
          fontIcon='bi-layers'
        />
      )}
    </>
  )
}

export {SidebarMenuMain}
