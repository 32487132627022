/* eslint-disable jsx-a11y/anchor-is-valid */
import {FC} from 'react'
import {useIntl} from 'react-intl'
import {KTCard, QUERIES, toAbsoluteUrl} from '../../../_dashboard/helpers'
import {PageTitle} from '../../../_dashboard/layout/core'
import {
  // ListsWidget2,
  // ListsWidget3,
  // ListsWidget4,
  // ListsWidget6,
  // TablesWidget5,
  // TablesWidget10,
  // MixedWidget8,
  // CardsWidget7,
  CardsWidget17,
  // CardsWidget20,
  // ListsWidget26,
  EngageWidget10,
  StatisticsWidget2,
} from '../../../_dashboard/partials/widgets'
import InvoicesTable from '../../../_dashboard/partials/widgets/_new/table/Avancement_des_projets'
import {useQuery} from 'react-query'
import {getAll} from '../../services/common_requests'
import {Link} from 'react-router-dom'

const DashboardPage: FC = () => {
  const {
    isLoading,
    data: stats,
    error,
  } = useQuery(
    `${QUERIES.STATISTICS}`,
    () => {
      return getAll({}, '/stats')
    },
    {
      cacheTime: 0,
      onError: (err) => {
        console.error(err)
      },
    }
  )

  return (
    <>
      {/* begin::Row */}
      <div className='row g-5 g-xl-10 mb-5 mb-xl-10 my-6'>
        {/* begin::Col */}
        <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo_TOTAL_devis`}>
          <Link to='/client-devis/list'>
            <StatisticsWidget2
              className='card-xl-stretch mb-xl-8'
              avatar={''}
              title={'Nombre total de devis'}
              value={stats?.data?.devis?.total}
              description={''}
            />
          </Link>
        </div>
        <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo_TOTAL_rec`}>
          <Link to='/complaints/complaint'>
            {' '}
            <StatisticsWidget2
              className='card-xl-stretch mb-xl-8'
              avatar={''}
              title={'Nombre total de réclamtion'}
              value={stats?.data?.reclamations?.total}
              description={''}
            />
          </Link>
        </div>
        <div className='col-xxl-4 col-xl-6 col-lg-6 col-md-4 my-2' key={`statInfo_TOTAL_ads`}>
          <Link to='/publicity/publicity'>
            {' '}
            <StatisticsWidget2
              className='card-xl-stretch mb-xl-8'
              avatar={''}
              title={'Nombre total de publicité'}
              value={stats?.data?.advertisement?.total}
              description={''}
            />
          </Link>
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xxxl-3 mb-md-5 mb-xl-50' style={{height: '100%'}}>
          <div>
            <KTCard>
              <InvoicesTable />
            </KTCard>
          </div>
        </div>
      </div>
      <div className='row g-5 g-xl-8'>
        <div className='col-xxl-6' style={{height: '100%'}}>
          <EngageWidget10 className='h-md-100' />
        </div>
        <div
          className='col-md-6 col-lg-6 col-xl-6 col-xxxl-3 mb-md-5 mb-xl-50'
          style={{height: '100%'}}
        >
          <div>
            <CardsWidget17
              className='h-md-100 col-xxxl-6 '
              data={stats?.data?.advertisement}
              name='ADS'
            />
          </div>
        </div>
      </div>
      {/* end::Col */}

      <div className='row mt-4 g-5 g-xl-8'>
        <div className='col-xxl-6' style={{height: '100%'}}>
          <CardsWidget17 className='h-md-100 col-xxxl-6 ' data={stats?.data?.devis} name='DEVIS' />
        </div>
        <div
          className='col-md-6 col-lg-6 col-xl-6 col-xxxl-3 mb-md-5 mb-xl-50'
          style={{height: '100%'}}
        >
          <div>
            <CardsWidget17
              className='h-md-100 col-xxxl-6 '
              data={stats?.data?.reclamations}
              name='REC'
            />
          </div>
        </div>
      </div>

      {/* end::Row */}

      <div className='row mt-4 g-5 g-xl-8'>
        {' '}
        <div className='col-xxl-6' style={{height: '100%'}}>
          <CardsWidget17 className='h-md-100 col-xxxl-6 ' data={stats?.data?.devisProvider} name='DEVIS_PROVIDER' />
        </div>
      </div>
    </>
  )
}

const DashboardWrapper: FC = () => {
  const intl = useIntl()
  return (
    <>
      <PageTitle breadcrumbs={[]}>{intl.formatMessage({id: 'MENU.DASHBOARD'})}</PageTitle>
      <DashboardPage />
    </>
  )
}

export {DashboardWrapper}
